import { useState, useEffect, useRef } from "react";
import { Paper, Text, Center, Box, Image, Button, Title, Group, ActionIcon, Menu, rem, Modal, TextInput, Loader, Stack, Divider, AppShell  } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight, IconPlayerPlay, IconSquareCheck, IconSquare, IconCircleCheckFilled } from '@tabler/icons-react';
import itemsScreens from './itemsScreens.json';
import languageElements from './languageElements.json';
import classes from './SessionPage.module.css';

export const localData = {
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get(key) {
        const stored = localStorage.getItem(key);
        return stored == null ? undefined : JSON.parse(stored);
    },
    remove(key) {
        localStorage.removeItem(key);
    }
  };


const AudioPlayer = ({ audioUrl, canAutoPlay }) => {
  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  // auto play audio when page loads
  useEffect(() => {
    if (canAutoPlay)
      playAudio();
  }, [audioUrl]);

  return (
    <>
      <ActionIcon variant="outline" color="black" aria-label="play" radius="xl" size="lg" onClick={playAudio}>
        <IconPlayerPlay/>
      </ActionIcon>
      <audio ref={audioRef} key={audioUrl}>
        <source src={audioUrl}></source>
      </audio>
    </>
  );
}

const VideoPlayer = ({ videoRef, canAutoPlay, videoUrl }) => {
  const playVideo = () => {
    try {
      if (videoRef.current)
        videoRef.current.play();
    }
    catch (error) {
      console.error('Video play error:', error);
    }
  };

  // reload and auto play video when page loads (unlike in images, the video does not reload when the src changes)
  useEffect(() => {
    try {
      if (canAutoPlay) {
        if(videoRef.current.readyState > 0) { // reload only if something was already loaded
          videoRef.current.pause(); // pause video play/load before trying to load
          videoRef.current.load(); // Reloads the video source
        }
        playVideo();
      }
    }
    catch (error) {
      console.error('Video update error:', error);
    }
  }, [videoUrl]);

  return (
    <>
      <ActionIcon variant="outline" color="black" aria-label="play" radius="xl" size="lg" onClick={playVideo}>
        <IconPlayerPlay/>
      </ActionIcon>
    </>
  );
}

const LevelChangeButton = () => {

  const [opened, { open, close }] = useDisclosure(false);
  const emailRef = useRef(null);
  const levelRef = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleLevelRequest = async () => {
    const emailText = emailRef.current.value;
    const levelText = levelRef.current.value;

    try {
      const response = await fetch('/api/report_level_request', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: emailText, level: levelText }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('API response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <>
      <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button variant="outline" color="black" radius="xl">
              Change Level
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Adjust text level</Menu.Label>
            <Menu.Item leftSection={<IconSquareCheck style={{ width: rem(14), height: rem(14) }} />}>
              Level 1
            </Menu.Item>
            <Menu.Item leftSection={<IconSquare style={{ width: rem(14), height: rem(14) }}/>} onClick={open}>
              Level 2
            </Menu.Item>
            <Menu.Item leftSection={<IconSquare style={{ width: rem(14), height: rem(14) }} />} onClick={open}>
              Level 3
            </Menu.Item>
          </Menu.Dropdown>
      </Menu>
      <Modal opened={opened} onClose={close} title="More Levels Coming Soon!">
            `<Text>We're developing more levels.  Enter your email and your level to be notified when they're ready.</Text>
            <Text mt="sm">Thank you for your patience and interest!</Text>
            <TextInput disabled={formSubmitted} ref={emailRef} label="Email" labelProps="" placeholder="me@gmail.com" mt="xs"/>
            <TextInput disabled={formSubmitted} ref={levelRef} label="Level" labelProps=""/>
            <Center mt="sm">
              <Button variant="filled" size="md" disabled={formSubmitted} onClick={() => {setFormSubmitted(true); handleLevelRequest();}}>Submit</Button>
            </Center>
            {formSubmitted && <Group>
                <IconCircleCheckFilled color="teal"/>
                <Text>Submitted</Text>
                </Group>
            }
      </Modal>`
    </>
  );
}

function InfoBox({activeElement}) {
  const elementIds = activeElement.element_ids;
  const hasMultipleElements = elementIds !== null && Array.isArray(elementIds) && elementIds.length > 1

  return(
    <Box className={classes.info_box} p="md">
    <Text>{activeElement['meaning']}</Text>
    {('info' in activeElement) &&
      <Text>{activeElement['info']}</Text>
    }
    {hasMultipleElements && 
      <>
        <Divider my="xs" label="Breakdown" labelPosition="center" color="black"/>
        
        {elementIds.map((elementId, index) => {
          const languageElement = languageElements[elementId];
          return(
            <>
            {index > 0 && <Divider my="xs" />}
            <Group>
              <Text key={index}>{index+1}</Text> 
              <Text key={index}>{languageElement['name']}</Text> 
              {languageElement['meaning'] && (<><Divider orientation="vertical" /><Text key={index}>{languageElement['meaning']}</Text></>)}
            </Group>
            <Text key={index} style={{ whiteSpace: 'pre-line' }} mt="md">{languageElement['info']}</Text> 
            
            </>
          );
          })}
      </>
    }
    </Box>
  );
}

function TranslationBox({translation}){
  return(
    <Box className={classes.info_box} p="md">
      <Text>{translation}</Text>
    </Box>
  );
}

function TextSegments({segments, highlightAll, highlightSegmentIndex}) {
  return (
      <Box className={highlightAll ? classes.highlight : ""}>
          {segments.map((segment, index) => {
            return(
              <Text span key={index} className={index === highlightSegmentIndex  ? classes.highlight : ""}>{segment['text']}</Text>
              
            );
          })}
      </Box>

  );
}

function ItemValue(){
  return(
    <Box >
      <Title order={5} mb="sm">You will learn:</Title>
      <Group mb="xs">
          <ActionIcon variant="transparent" size="lg" mr="xs">
            <Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/words_diamond.webp" />
          </ActionIcon>
        <Text>26 words</Text>
      </Group>
      <Group>
        <ActionIcon variant="transparent" size="lg" mr="xs">
          <Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/grammars_diamond.webp" />
        </ActionIcon>
        <Text>4 grammar points</Text>
      </Group>
    </Box>
  );
}

function ItemContentScreen({itemScreens, numCurrentScreen, nextScreen, prevScreen, saveProgress}) {
  const content = itemScreens[numCurrentScreen];
  const isLast = numCurrentScreen === itemScreens.length - 1;

  // handle image while loading
  const [imgLoading, setImgLoading] = useState(true);

  const [showTranslation, setShowTranslation] = useState(false);

  // info text
  const [activeElement, setActiveElement] = useState(null);
  // hold the index of the current element to show. -1 is nothing, then the index corresponds to the current
  // segment (that has  info). after that, it shows the sentence translation.
  const [activeElementIndex, setActiveElementIndex] = useState(-1);

  // handle screen change
  useEffect(() => {
    window.scrollTo(0, 0);  // scroll back to top
    setShowTranslation(false);
    setActiveElement(null);
    setActiveElementIndex(-1);
  }, [content]);
  useEffect(() => {
    setImgLoading(true);
  }, [content.imgUrl]);

  // enable auto play only after a button is clicked (Google Chrome policy)
  const [canAutoPlay, setCanAutoPlay] = useState(true);

  const videoRef = useRef(null);

  const getIndicesOfMeaningfulSegments = (segments) => {
      return segments
        .map((segment, index) => segment['meaning'] !== null ? index : -1)
        .filter(index => index !== -1);
  };

  var indicesOfMeaningfulSegments = null;
  if (content.segments) {
    indicesOfMeaningfulSegments = getIndicesOfMeaningfulSegments(content.segments);
  }

  const markLanguageElementsAsLearned = (segments) => {
    const learnedElementIds = segments.flatMap(segment => {
      if (segment.element_ids) {
        return segment.element_ids;
      } else {
        return [];
      }
    });
    saveProgress(learnedElementIds);
  };
  

  const handleNextButtonClick = () => {
    // setCanAutoPlay(true);
    if (content.segments) {
        if (activeElementIndex < indicesOfMeaningfulSegments.length - 1) {
            setShowTranslation(false);
            setActiveElement(content.segments[indicesOfMeaningfulSegments[activeElementIndex + 1]]);
            setActiveElementIndex(activeElementIndex + 1);
        }
        else if (activeElementIndex === indicesOfMeaningfulSegments.length - 1){
            setShowTranslation(true);
            setActiveElement(null);
            setActiveElementIndex(activeElementIndex + 1);
        }
        else if (activeElementIndex >= indicesOfMeaningfulSegments.length){
            markLanguageElementsAsLearned(content.segments);
            nextScreen();
        }
    }
    else {
        nextScreen();
    }
  };
  const handlePrevButtonClick = () => {
    // setCanAutoPlay(true);
    if (content.segments) {
        if (activeElementIndex > 0) {
            setShowTranslation(false);
            setActiveElement(content.segments[indicesOfMeaningfulSegments[activeElementIndex - 1]]);
            setActiveElementIndex(activeElementIndex - 1);
        }
        else if (activeElementIndex === 0){
            setShowTranslation(false);
            setActiveElement(null);
            setActiveElementIndex(activeElementIndex - 1);
        }
        else if (activeElementIndex <= -1){
            prevScreen();
        }
    }
    else {
        prevScreen();
    }
  };

  return (
    <>
      {content.title && <Center><Title order={1} m="sm">{content.title}</Title></Center>}
 
      {('vidUrl' in content) && 
        <>
          <video width="100%" ref={videoRef} onLoadedData={() => setImgLoading(false)} style={{display: imgLoading? "none":"block"}}>
            <source src={content.vidUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {imgLoading && <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader color="gray" type="bars" /></Box>}
        </>
      }
      {('imgUrl' in content && !('vidUrl' in content)) && 
        <Center>
          <Image src={content.imgUrl} h={300} fit="contain" onLoad={() => setImgLoading(false)} style={{display: imgLoading? "none":"block"}}/>
          {imgLoading && <Box h={300} style={{display: 'flex', alignItems: 'center'}}><Loader color="gray" type="bars" /></Box>}
        </Center>
      }

      {content.showValue && <Center><Box mt="xl"><ItemValue/></Box></Center>}

      {content.text &&
        (<>
          <Center>
            <Stack gap="xs" m="sm">
              <Center><TextSegments segments={content.segments} highlightAll={activeElementIndex===indicesOfMeaningfulSegments.length} highlightSegmentIndex={indicesOfMeaningfulSegments[activeElementIndex]}/></Center>
              {showTranslation && <TranslationBox translation={content['translation']}/>}
              {activeElement && <InfoBox activeElement={activeElement}/>}
            </Stack>
          </Center>
        </>)
      }

      

      <AppShell.Footer bg="var(--mantine-color-gray-light)" >
          <Center>
            <Paper shadow="sm" withBorder style={{ width: '600px'}}>
              <Center mt="xs" mb="xs">
                <Button variant="outline" radius="xl" size="md" color="black" mr="sm" onClick={handlePrevButtonClick}><IconChevronLeft stroke={3}/> </Button>
                {('vidUrl' in content) && <VideoPlayer videoRef={videoRef} videoUrl={content.vidUrl} canAutoPlay={canAutoPlay}/>}
                {('audio' in content && !('vidUrl' in content)) && <AudioPlayer audioUrl={content.audio} canAutoPlay={canAutoPlay}/>}
                <Button color={isLast && "green"} radius="xl" size="md" ml="sm" onClick={handleNextButtonClick}>{(isLast && activeElementIndex === indicesOfMeaningfulSegments.length) ? "End Chapter" : <IconChevronRight stroke={3} /> }</Button>
              </Center>
              <Center><Text>Page {numCurrentScreen + 1}/{itemScreens.length}</Text></Center>
            </Paper>
          </Center>
      </AppShell.Footer>
    </>
  );
}

function ItemSummaryScreen({nextScreen}) {
  return (
    <>
    <Center m="sm"><Title>You learned</Title></Center>
    <Center mt="lg">
      <ActionIcon variant="transparent" size="xl">
        <Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/words_diamond.webp" />
      </ActionIcon>
    </Center>
    <Center><Text>26 words</Text></Center>
    <Center mt="md">
      <ActionIcon variant="transparent" size="xl">
        <Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/grammars_diamond.webp" />
      </ActionIcon>
    </Center>
    <Center><Text>4 grammar points</Text></Center>
    <Center mt="lg"><Image h={300} fit="contain" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/treasure_chest.webp"/></Center>

    <AppShell.Footer bg="var(--mantine-color-gray-light)" >
          <Center>
            <Paper shadow="sm" withBorder style={{ width: '600px'}}>
              <Center mt="xs" mb="xs">
                <Button radius="xl" size="md" ml="sm" onClick={nextScreen}>Next Item</Button>
              </Center>
            </Paper>
          </Center>
      </AppShell.Footer>
    </>
  );
}

function SessionPage({saveProgress}) {

  const { itemId, pageId } = useParams();
  const [currentItemScreen, setCurrentItemScreen] = useState(() => (parseInt(pageId, 10) - 1) || 0);
  const [showSummaryScreen, setShowSummaryScreen] = useState(false);
  const itemScreens = itemsScreens[itemId];

  const navigate = useNavigate();
  function sessionDone() {
    navigate("/updates");
  }


  const prevScreen = () => {
    if (currentItemScreen > 0) {
      setCurrentItemScreen((currentItemScreen) => currentItemScreen - 1);
      navigate(`/item/${itemId}/${currentItemScreen + 0}`);
    }
  };

  const nextScreen = () => {
    if (currentItemScreen < itemScreens.length - 1) {
      setCurrentItemScreen((currentItemScreen) => currentItemScreen + 1);
      navigate(`/item/${itemId}/${currentItemScreen + 2}`);
    }
    else if (currentItemScreen === itemScreens.length - 1 && !showSummaryScreen) {
      setShowSummaryScreen(true);
      navigate(`/item/${itemId}/summary`);
    }
    else {
      sessionDone();
    }
  };


  return (
    <>
        <Box bg="var(--mantine-color-gray-light)" style={{ paddingBottom: '87px' }}>
          <Center>
            <Paper shadow="sm" withBorder pb="sm" style={{ width: '600px', minHeight: '100vh' }}>
                
                  {showSummaryScreen && <ItemSummaryScreen nextScreen={nextScreen}/>}
                  {!showSummaryScreen && <ItemContentScreen itemScreens={itemScreens} numCurrentScreen={currentItemScreen} nextScreen={nextScreen} prevScreen={prevScreen} saveProgress={saveProgress}/>}
                
            </Paper>
          </Center>
        </Box>
    </>
  );
}

export default SessionPage;
